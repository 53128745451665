
import projectConstants from '~/store/project/-constants'
import availabilityConstants from "~/store/availability/-constants";
import {ProjectFocusMode} from "~/store/building/-constants";

export default {
  name: 'MoleculeProjectFilters',
  computed: {
    measurements() {
      return this.$store.state?.base?.meta?.generalConfig?.measurements || null
    },
    measurementsEnabled() {
      return this.measurements?.isEnabled || false
    },
    measurementsItems() {
      if (this.measurementsEnabled && this.measurements.types && this.measurements.types.length > 0) {
        return this.measurements.types
      }
      return []
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : this.$t('sqm')
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    projects() {
      return this.$store.state.project.projects
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    activeProjectData() {
      return this.projects.find((p) => p.slug === this.activeProject)
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
  },
  watch: {},
  mounted() {},
  methods: {
    isRadioChecked(measurement) {
      return this.surfaceMultiplier === measurement.multiplier
    },
    handleSelectMeasurement(value) {
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.UPDATE_SURFACE_MULTIPLIER),
        Number(value)
      )
    },
    handleSelectMeasurementLabel(dataText) {
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.UPDATE_SURFACE_NAME),
        dataText
      )

      const surfaceField = dataText.toLowerCase() !== 'gla' ? dataText.toLowerCase(): 'sqm';
      Object.entries(this.storeFilters.surfaceRange).forEach(([key, entry]) => {
        if(key !== surfaceField) {
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              surfaceRange: {
                ...this.storeFilters.surfaceRange,
                [key]: this.activeProjectData.surfaceRange[key]
              },
            }
          )
        }
      })
      this.$store.dispatch(
        availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
        {
          surface_field: surfaceField
        }
      )
    },
  }
}
