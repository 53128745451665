import { render, staticRenderFns } from "./MoleculeSidebarResult.vue?vue&type=template&id=d6ead9bc&"
import script from "./MoleculeSidebarResult.vue?vue&type=script&lang=js&"
export * from "./MoleculeSidebarResult.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeSidebarResult.vue?vue&type=style&index=0&id=d6ead9bc&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeSidebarResult.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fproject%2Fbuilding%2FMoleculeSidebarResult.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesProjectBuildingMoleculeSpaceDetailsTotalResult: require('/app/components/molecules/project/building/MoleculeSpaceDetailsTotalResult.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,MoleculesProjectBuildingMoleculeSidebarResultSpaceDetails: require('/app/components/molecules/project/building/MoleculeSidebarResultSpaceDetails.vue').default})
