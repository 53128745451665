
export default {
  name: 'MoleculeSpaceDetailsTotalResult',
  props: {
    type: {
      required: true,
      type: String,
      default: ''
    },
    floor: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    fullyLeased: {
      required: true,
      type: Boolean,
      default: false,
    }
  },
  computed: {
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 1
    }
  }
}
