
import { ProjectFocusMode } from '~/store/building/-constants'
import availabilityConstants from '~/store/availability/-constants'
import { getElementHalfWidth } from '~/helpers/util';
import {SurfaceRangeOptions} from "~/store/availability/-types";

function debounceAsync(func, wait) {
  let timeout;
  return function(...args) {
    return new Promise((resolve, reject) => {
      const later = () => {
        clearTimeout(timeout);
        try {
          resolve(func.apply(this, args));
        } catch (error) {
          reject(error);
        }
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    });
  };
}

export default {
  name: 'MoleculeProjectFiltersDropdown',
  components: {},
  data() {
    return {
      sliderSurfaceValues: [0, 0],
      sliderFloorValue: 3,
      surfaceRange: [0, 0]
    }
  },
  computed: {
    storeFilters() {
      return this.$store.state.availability.filters
    },
    projects() {
      return this.$store.state.project.projects
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.floors
    },
    floorOptions() {
      let opts = [...this.floors].map((f) => {
        const fNumber = f.name.split(' ')[1]
        return {
          value: f.no,
          text: fNumber ? `${this.$t('floor')} ${f.no}` : `${this.$t(f.name.toLowerCase())}`,
          no: f.no,
          building_id: f.building_id,
          id: f.id
        }
      })
      if (!this.storeFilters.buildingId) {
        opts = this.uniqueByProperty(opts, 'no')
        opts.unshift({ text: this.$t('any'), value: '' })
        return opts
      }
      opts = opts.filter((f) => f.building_id === this.storeFilters.buildingId)
      opts.unshift({ text: this.$t('any'), value: '' })
      return opts
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    activeProjectData() {
      return this.projects.find((p) => p.slug === this.activeProject)
    },
    includeLeasedSpacesInResultsList() {
      return this.$store.state.availability.includeLeasedSpaces
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    visibleLeasedSpaces() {
      return this.projectFeatures?.visibleLeasedSpaces
    },
    measurements() {
      return this.$store.state?.base?.meta?.generalConfig.measurements || null
    },
    measurementsEnabled() {
      return this.measurements?.isEnabled || false
    },
    displayType() {
      let type = 'default'
      if (this.storeFilters.buildingId) {
        type = 'building'
      }
      if (typeof this.storeFilters.floorNo !== 'undefined' || this.storeFilters.floorId) {
        type = 'floor'
      }
      return type
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    clientManager() {
      return this.engine3d.default()
    },
    buildingFilterData() {
      return this.$store.state.availability.project.find((b) => b.id === this.storeFilters.buildingId)
    },
    floorFilterData() {
      return this.$store.state.availability.building.find((f) => f.id === this.storeFilters.floorId)
    },
    isFilterOpen() {
      return this.$store.state.base.isFilterOpen
    },
    selectedSpace() {
      return this.$store.state.building.space.spaceData
    },
    hasSelectedSpace() {
      return !!this.selectedSpace
    },
    isMenuOpen() {
      return this.$store.state.base.isAvailabilityMenuOpen
    },
    surfaceField(){
      return this.storeFilters?.surface_field || SurfaceRangeOptions.GLA
    },
    clearedFilters() {
      return this.storeFilters.clearedFilters || false;
    }
  },
  watch: {
    surfaceField(newVal, oldVal) {
      if(newVal !== oldVal) {
        const {min = 0, max = 0} = this.storeFilters.surfaceRange[newVal];
        this.surfaceRange = [min, max]
      }
    },
    activeProjectData(newVal, oldVal) {
      this.setSurface();
      this.handleFilterChange(newVal.id, ProjectFocusMode.DEFAULT)
      this.$nextTick(() => {
        this.loadDataForFilters(ProjectFocusMode.DEFAULT)
      })
    },
    sliderSurfaceValues(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleFilterChange({
          [this.surfaceField]: {
            min: newVal[0],
            max: newVal[1]
          }
        }, 'surfaceRange')
      }
    },
    surfaceRange(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.sliderSurfaceValues = newVal;
        // this.setDefaultStoreSurfaceFilters()
      }
    },
    clearedFilters(newVal, oldVal) {
      if(newVal !== oldVal && newVal) {
        const {min = 0, max = 0} = this.storeFilters.surfaceRange[this.surfaceField];
        this.surfaceRange = [min, max];
      }
    },
    storeFilters() {
      this.$nextTick(() => {
        this.loadDataForFilters(this.displayType);
        this.cameraFocusTo(this.displayType);
      })
    },
    includeLeasedSpacesInResultsList(newVal, oldVal) {
      this.$nextTick(() => {
        this.loadDataForFilters(this.displayType)
      })
    },
    displayType(type) {
      this.$store.dispatch('building/changeProjectFocusMode', type);
    }
  },
  mounted() {
    this.handleFilterChange(this.activeProjectData.surfaceRange, 'surfaceRange')
    this.loadDataForFilters(ProjectFocusMode.DEFAULT);
    this.initializeInteractions();
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === availabilityConstants.withNamespace(availabilityConstants.mutation.RESET_FILTERS)) {
        this.sliderSurfaceValues = this.surfaceRange;
        this.$refs.availableStatusSelector?.handleSelect('available');
      }
    })
  },
  methods: {
    cameraFocusTo(type) {
      const elementHalfWidth = getElementHalfWidth('#organism-sidebar-project');
      const offsetValueX = (this.isMenuOpen && window.innerWidth > 900) ? -elementHalfWidth : 0;
      const offsetValueY = window.innerWidth <= 900 ? -50 : 0;
      const offsetTarget = { x: offsetValueX, y: offsetValueY };
      switch(type) {
        case 'building':
          if (this.buildingFilterData) {
            const buildingCode = this.buildingFilterData.code.toLowerCase();
            this.clientManager?.cameraFocusTo(buildingCode, "", offsetTarget)
          }
          break;
        case 'floor':
          if ((this.floorFilterData && this.buildingFilterData) || this.hasSelectedSpace) {
            const buildingCode = this.hasSelectedSpace ? this.selectedSpace?.floor?.building?.code.toLowerCase() : this.buildingFilterData.code.toLowerCase();
            const floorCode = this.hasSelectedSpace ? this.selectedSpace?.floor?.code.toLowerCase() : this.floorFilterData.code.toLowerCase();

            if (this.hasSelectedSpace) {
              const { space_code: spaceCode, available } = this.selectedSpace
              const spacesCode = spaceCode.split(';')
              const spaceData = { available, spacesCode }
              this.clientManager?.cameraFocusTo(buildingCode, floorCode, offsetTarget, spaceData);
            } else {
              this.clientManager?.cameraFocusTo(buildingCode, floorCode, offsetTarget)
            }
          }
          break;
        case 'default':
        default:
          this.clientManager?.cameraFocusTo("", "", offsetTarget)
      }
    },
    initializeInteractions() {
      if (!this.clientManager) {
        setTimeout(() => {this.initializeInteractions()}, 100)
        return;
      }
      this.clientManager.onBuildingInteraction = (codes, type) => {
        this.$store.dispatch('building/changeProjectFocusMode', type);
        this.$store.dispatch('building/viewSpace', {})
        let building = {};
        let floor = {};
        switch (type) {
          case 'building':
            building = this.buildings.find((b) => b.code.toLowerCase() === codes.building.toLowerCase());
            this.$store.dispatch(
              availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
              {
                buildingId: building.id,
                floorNo: undefined,
                floorId: undefined,
                return_not_available: true
              }
            )
            break;
          case 'floor':
            building = this.buildings.find((b) => b.code.toLowerCase() === codes.building.toLowerCase());
            floor = this.floors.find((f) => f.code.toLowerCase() === codes.floor.toLowerCase() && f.building_id === building.id);
            if (floor.id !== this.storeFilters.floorId) {
              this.$store.dispatch(
                availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
                {
                  buildingId: building.id,
                  floorNo: floor.no,
                  floorId: floor.id,
                  return_not_available: this.includeLeasedSpacesInResultsList
                }
              )
            } else {
              const elementHalfWidth = getElementHalfWidth('#organism-sidebar-project');
              const offsetValueX = (this.isMenuOpen && window.innerWidth > 900) ? -elementHalfWidth : 0;
              const offsetValueY = window.innerWidth <= 900 ? -50 : 0;
              const offsetTarget = { x: offsetValueX, y: offsetValueY };
              this.clientManager?.cameraFocusTo(building.code.toLowerCase(), floor.code, offsetTarget);
              this.clearSelectedSpace();
            }
            break;
          case 'default':
          default:
            this.$store.dispatch(availabilityConstants.withNamespace(availabilityConstants.action.CLEAR_FILTERS));
        }
      }
    },
    uniqueByProperty(array, propName) {
      const seen = new Set()
      return array.filter((item) => {
        const val = item[propName]
        if (seen.has(val)) {
          return false
        }
        seen.add(val)
        return true
      })
    },
    handleStatusSelect(status) {
      let isChecked = false;
      switch (status) {
        case 'available':
          this.clearSelectedSpace()
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              filters: {
                available: true,
              },
              return_not_available: false
            }
          )
          isChecked = false;
          break
        case 'leased':
          this.clearSelectedSpace()
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              filters: {
                available: false
              },
              return_not_available: false,
            }
          )
          isChecked = false;
          break
        case 'all':
          this.clearSelectedSpace()
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              filters: {},
              return_not_available: true,
            }
          )
          isChecked = true;
          break
      }
      this.$store.dispatch(
        availabilityConstants.withNamespace(
          availabilityConstants.action.UPDATE_LEASED_SPACES_VISIBILITY
        ),
        isChecked
      )
    },
    handleFilterChange(input, type) {
      switch (type) {
        case 'surfaceRange':
          this.clearSelectedSpace()
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              return_not_available: [ProjectFocusMode.DEFAULT, ProjectFocusMode.BUILDING].includes(this.displayType) ? true : this.includeLeasedSpacesInResultsList,
              surfaceRange: {
                ...this.storeFilters.surfaceRange,
                ...input,
              },
              clearedFilters: false,
            }
          )
          break
        case 'building':
          this.clearSelectedSpace()
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              buildingId: input.value,
              floorNo: undefined,
              floorId: undefined,
              return_not_available: true
            }
          )
          break
        case 'floor':
          this.clearSelectedSpace()
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              floorNo: input.value,
              floorId: undefined,
              return_not_available: this.includeLeasedSpacesInResultsList
            }
          )
          break
        case 'default':
        default:
          this.clearSelectedSpace()
          this.$store.dispatch(
            availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
            {
              projectId: input,
              buildingId: undefined,
              floorNo: undefined,
              floorId: undefined,
              return_not_available: true
            }
          )
      }
    },
    loadDataForFilters: debounceAsync(async function (type = ProjectFocusMode.DEFAULT) {
      let action = ''
      switch (type) {
        case 'building':
          action = availabilityConstants.action.LOAD_BUILDING_AVAILABILITY_DATA
          this.dataType = 'building'
          break
        case 'floor':
          action = availabilityConstants.action.LOAD_BUILDING_AVAILABILITY_DATA
          this.dataType = 'floor'
          break
        case 'space':
          action = availabilityConstants.action.LOAD_BUILDING_AVAILABILITY_DATA
          this.dataType = 'space'
          break
        case 'default':
        default:
          action = availabilityConstants.action.LOAD_PROJECT_AVAILABILITY_DATA
          this.dataType = 'default'
      }
      const {clearedFilters, surfaceRange, ...restStoreFilters} = this.storeFilters
      await this.$store.dispatch(availabilityConstants.withNamespace(action), {
        ...restStoreFilters,
        min_surface: surfaceRange[this.surfaceField].min,
        max_surface: surfaceRange[this.surfaceField].max,
      });
      await this.$store.dispatch(
        availabilityConstants.withNamespace(availabilityConstants.action.LOAD_SPACE_AVAILABILITY_DATA),
        {
          ...restStoreFilters,
          min_surface: surfaceRange[this.surfaceField].min,
          max_surface: surfaceRange[this.surfaceField].max,
          return_not_available: this.includeLeasedSpacesInResultsList
        }
      );
      this.clientManager?.forceUpdatePinAndLabelPositions();
    }, 150),
    // setDefaultStoreSurfaceFilters() {
    //   this.$store.dispatch(
    //     availabilityConstants.withNamespace(availabilityConstants.action.UPDATE_FILTERS),
    //     {
    //       surfaceRange: {
    //         ...this.storeFilters.surfaceRange,
    //         [this.surfaceField] : {
    //           min: this.surfaceRange[0],
    //           max: this.surfaceRange[1]
    //         },
    //       },
    //     }
    //   )
    // },
    async clearSelectedSpace() {
      return this.$store.dispatch('building/viewSpace', {})
    },
    setSurface() {
      const {min, max} = this.activeProjectData.surfaceRange[this.surfaceField] || this.activeProjectData.surfaceRange || {min: 0, max: 9999};
      this.surfaceRange = [min, max]
    },
  }
}
