

import baseConstants from "~/store/base/-constants";
import availabilityConstants from "~/store/availability/-constants";
import projectConstants from "~/store/project/-constants";

export default {
  name: 'MoleculeProjectFiltersHeader',
  components: {  },
  data() {
    return {
    }
  },
  computed: {
    isFilterOpen() {
      return this.$store.state.base.isFilterOpen
    },
  },
  watch: {},
  mounted() {
    if (window.innerWidth > 900) {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.CHANGE_FILTER_STATE),
        true
      )
    }
  },
  methods: {
    toggleFilter() {
      if (!this.isFilterOpen) {
        this.$parent.$refs.organismSidebarProject?.scrollTo({ top: 0 });
      }
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_FILTER)
      )
    },
    clearFilters() {
      this.$store.dispatch(availabilityConstants.withNamespace(availabilityConstants.action.CLEAR_FILTERS))
      this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.RESET_SURFACE_FIELDS));
      this.clearSelectedSpace()
    },
    async clearSelectedSpace() {
      return this.$store.dispatch('building/viewSpace', {})
    }
  }
}
