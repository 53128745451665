
import { Fragment } from 'vue-frag'
import {SurfaceRangeOptions} from "~/store/availability/-types";

export default {
  name: 'MoleculeSidebarResultSpaceDetails',
  components: {
    Fragment
  },
  props: {
    type: {
      required: true,
      type: String,
      default: ''
    },
    space: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    showPeopleLabel: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
  computed: {
    unitOfMeasure() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure
    },
    measurementLabel() {
      return `${this.unitOfMeasureString} ${this.surfaceName}`
    },
    storeFilters() {
      return this.$store.state.availability.filters
    },
    surfaceField(){
      return this.storeFilters?.surface_field || SurfaceRangeOptions.GLA
    },
    spaceData() {
      const spaceData = {
        ...this.space,
        displayedPeople: this.unitOfMeasure !== 1 ?  Math.floor(this.space.sqm / 8) : Math.floor((this.space.sqm / 10.763910417) / 8),
        displayedSurface: typeof this.space?.[this.surfaceField] !== 'undefined' ? this.space[this.surfaceField] : this.space.sqm
      };
      // if (['nla', 'lfa'].includes(this.surfaceName.toLowerCase())) {
      //   if (typeof spaceData[this.surfaceName.toLowerCase()] !== 'undefined' && spaceData[this.surfaceName.toLowerCase()] !== null && spaceData[this.surfaceName.toLowerCase()] !== '') {
      //     // if lfa, nla measurement is not empty, then display the surface value from database
      //     spaceData.displayedSurface = spaceData[this.surfaceName.toLowerCase()]
      //   } else if (this.surfaceMultiplier !== 1) {
      //     // otherwise display the surface based on surface multiplier set within the general configuration object
      //     spaceData.displayedSurface = (Number(spaceData.sqm) - Number(spaceData.sqm) * Number(this.surfaceMultiplier)).toFixed(2)
      //   }
      // }
      return spaceData;
    },
    surfaceMultiplier() {
      return this.$store.state?.project?.surfaceMultiplier || 1
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.generalConfig.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    surfaceName() {
      return this.$store.state?.project?.surfaceName || 'GLA'
    },
    showGeneratedNumberOfPeople() {
      const commonFeatures =
        this.$store?.state?.base?.meta?.project?.sections?.find(
          (section) => section.type === 'Building'
        ) || {}
      if ('generatedNumberOfPeople' in commonFeatures) {
        return commonFeatures.generatedNumberOfPeople
      } else {
        return true
      }
    },
  },
}
